const FirebaseConfig = {
    apiKey: "AIzaSyCOGFUVOFmQcUqKF2SpYQEZEj5ehuySh2A",
    authDomain: "gamedb-bcace.firebaseapp.com",
    projectId: "gamedb-bcace",
    storageBucket: "gamedb-bcace.firebasestorage.app",
    messagingSenderId: "544323585254",
    appId: "1:544323585254:web:99716a96e41d2f67842727",
    measurementId: "G-1LFDH3JBWF"
};

export default FirebaseConfig;